
import { base_url } from "./constants";
import axios from 'axios';



//  AUTHENTICATION

export const AdminLogin = async (username, password) => {
    return await axios.post(`${base_url}/addmin/login/`, {
        email: username.trim(),
        password: password
    });
};


export const AdminSendResetPassword = async (email) => {
    return await axios.post(`${base_url}/addmin/send-reset-password/`, {
        email: email,
    });
};

export const AdminUpdatePassword = async (password, confPassword, uid, token) => {
    return await axios.post(`${base_url}/addmin/reset-password/${uid}/${token}/`, {
        password: password,
        password2: confPassword
    });
};


export const AdminGetDashboard = async () => {
    return await axios.get(`${base_url}/addmin/dashboard/`);
};

export const AdminGetDashboardCounts = async () => {
    return await axios.get(`${base_url}/addmin/dashboard-counts/`);
};

// CLIENTS
export const AdminGetClients = async () => {
    return await axios.get(`${base_url}/addmin/clients/`);
};

// AGENCIES
export const AdminGetAgencies = async () => {
    return await axios.get(`${base_url}/addmin/agencies/`);
};


export const AdminGetAgencyProfile = async (aid) => {
    return await axios.get(`${base_url}/addmin/agency-profile/${aid}/`);
};


export const AdminRegisterAgency = async (data) => {
    return await axios.post(`${base_url}/addmin/register-agency/`, data);
};

export const AdminAcceptProfile = async (data) => {
    return await axios.post(`${base_url}/addmin/accept-profile/`, data);
};

export const AdminGetAgencyMargins = async () => {
    return await axios.get(`${base_url}/addmin/agency-margins/`);
};

export const AdminSaveAgencyMargins = async (data) => {
    return await axios.post(`${base_url}/addmin/agency-margins/`, data);
};





// TYPES
export const AdminGettTypes = async () => {
    return await axios.get(`${base_url}/addmin/tour-type/`);
};

export const AdminTourType = async (data) => {
    return await axios.post(`${base_url}/addmin/tour-type/`, data);
};


export const AdminDeleteTourType = async (id) => {
    return await axios.delete(`${base_url}/addmin/tour-type/${id}`);
};




// FACILITY
export const AdminGettFacility = async () => {
    return await axios.get(`${base_url}/addmin/facility/`);
};

export const AdminTourFacility = async (data) => {
    return await axios.post(`${base_url}/addmin/facility/`, data);
};

export const AdminDeleteTourFacility = async (id) => {
    return await axios.delete(`${base_url}/addmin/facility/${id}`);
};






// TOURS
export const AdminGetTours = async () => {
    return await axios.get(`${base_url}/addmin/tours/?agency_id=${-1}`);
};
export const AdminGetToursId = async (id) => {
    return await axios.get(`${base_url}/addmin/tours/${id}/`);
};
export const AdminCreateTour = async (data) => {
    return await axios.post(`${base_url}/addmin/tours/`, data);
};
export const AdminDeleteTour = async (id) => {
    return await axios.delete(`${base_url}/addmin/tours/${id}/`);
};


// Hajjs
export const AdminGetHajjs = async () => {
    return await axios.get(`${base_url}/addmin/hajjs/?agency_id=${-1}`);
};
export const AdminGetHajjsId = async (id) => {
    return await axios.get(`${base_url}/addmin/hajjs/${id}/`);
};
export const AdminCreateHajj = async (data) => {
    return await axios.post(`${base_url}/addmin/hajjs/`, data);
};
export const AdminDeleteHajj = async (id) => {
    return await axios.delete(`${base_url}/addmin/hajjs/${id}/`);
};


// Tickets
export const AdminGetTickets = async () => {
    return await axios.get(`${base_url}/addmin/tickets/?agency_id=${-1}`);
};
export const AdminGetTicketsId = async (id) => {
    return await axios.get(`${base_url}/addmin/tickets/${id}/`);
};
export const AdminCreateTicket = async (data) => {
    return await axios.post(`${base_url}/addmin/tickets/`, data);
};
export const AdminDeleteTicket = async (id) => {
    return await axios.delete(`${base_url}/addmin/tickets/${id}/`);
};

// VISAS
export const AdminGetVisas = async () => {
    return await axios.get(`${base_url}/addmin/visas/?agency_id=${-1}`);
};
export const AdminGetVisasId = async (id) => {
    return await axios.get(`${base_url}/addmin/visas/${id}/`);
};
export const AdminCreateVisa = async (data) => {
    return await axios.post(`${base_url}/addmin/visas/`, data);
};
export const AdminDeleteVisa = async (id) => {
    return await axios.delete(`${base_url}/addmin/visas/${id}/`);
};


// HOTELS
export const AdminGetHotels = async () => {
    return await axios.get(`${base_url}/addmin/hotels/?agency_id=${-1}`);
};
export const AdminCreateHotel = async (data) => {
    return await axios.post(`${base_url}/addmin/hotels/`, data);
};
export const AdminDeleteHotel = async (id) => {
    return await axios.delete(`${base_url}/addmin/hotels/${id}/`);
};


// ROOMS
export const AdminGetRooms = async (hid) => {
    return await axios.get(`${base_url}/addmin/rooms/?hotel_id=${hid}`);
};
export const AdminCreateRoom = async (data) => {
    return await axios.post(`${base_url}/addmin/rooms/`, data);
};
export const AdminDeleteRoom = async (id) => {
    return await axios.delete(`${base_url}/addmin/rooms/${id}/`);
};

// TRANSPORTS
export const AdminGetTransports = async () => {
    return await axios.get(`${base_url}/addmin/transports/?agency_id=${-1}`);
};
export const AdminGetTransportsId = async (id) => {
    return await axios.get(`${base_url}/addmin/transports/${id}/`);
};
export const AdminCreateTransport = async (data) => {
    return await axios.post(`${base_url}/addmin/transports/`, data);
};
export const AdminDeleteTransport = async (id) => {
    return await axios.delete(`${base_url}/addmin/transports/${id}/`);
};





// BOOKINGS
export const AgencyGetTransportBookings = async () => {
    return await axios.get(`${base_url}/addmin/transport-bookings/?agency_id=${-1}`);
};

export const AgencyGetRoomBookings = async () => {
    return await axios.get(`${base_url}/addmin/room-bookings/?agency_id=${-1}`);
};

export const AgencyGetTourBookings = async () => {
    return await axios.get(`${base_url}/addmin/tour-bookings/?agency_id=${-1}`);
};

export const AgencyGetVisaBookings = async () => {
    return await axios.get(`${base_url}/addmin/visa-bookings/?agency_id=${-1}`);
};

export const AgencyGetTicketBookings = async () => {
    return await axios.get(`${base_url}/addmin/ticket-bookings/?agency_id=${-1}`);
};

export const AgencyGetHajjBookings = async () => {
    return await axios.get(`${base_url}/addmin/hajj-bookings/?agency_id=${-1}`);
};





// ADDONS  SELECTORS
export const AgencyGetAddons = async (aid, type) => {
    return await axios.get(`${base_url}/agency/addons/?agency_id=${aid}&type=${type ? type : 'all'}`);
};
export const AgencyGetTypes = async (type) => {
    return await axios.get(`${base_url}/agency/types/?type=${type}`);
};
export const AgencyGetFeatures = async (type) => {
    return await axios.get(`${base_url}/agency/features/?type=${type}`);
};
export const AgencyGetMargins = async (aid) => {
    return await axios.get(`${base_url}/agency/margins/?agency_id=${aid}`);
};





//    STORE

export const AdminGetProductCategories = async () => {
    return await axios.get(`${base_url}/store/product-categories/`);
};

export const AdminAddProductCategory = async (finalData) => {
    return await axios.post(`${base_url}/store/product-categories/`, finalData);
};


export const AdminDeleteProductCategory = async (cid) => {
    return await axios.delete(`${base_url}/store/product-categories/${cid}/`);
};




export const AdminGetProducts = async () => {
    return await axios.get(`${base_url}/store/products/`);
};

export const AdminGetProductsId = async (id) => {
    return await axios.get(`${base_url}/store/products/${id}/`);
};



export const AdminGetStoreOrders = async () => {
    return await axios.get(`${base_url}/store/place-order/`);
};


export const AdminUpdateOrderStatus = async (data) => {
    return await axios.post(`${base_url}/store/update-order-status/`, data);
};


export const AdminAddProducts = async (finalData) => {
    return await axios.post(`${base_url}/store/products/`, finalData);
};

export const AdminAddVariantImage = async (id, pid, finalData) => {
    return await axios.post(`${base_url}/store/add-variant-image/${pid}/${id}/`, finalData);
};

export const AdminDeleteProducts = async (pid) => {
    return await axios.delete(`${base_url}/store/products/${pid}`);
};


export const AdminGetCatsForProducts = async () => {
    return await axios.get(`${base_url}/store/categories-for-products/`);
};


