import React from "react";
/** Add Route Component */
//Home Section

const SignIn = React.lazy(() => import("../pages/ExtraPages/SignIn"));
const SignUp = React.lazy(() => import("../pages/ExtraPages/SignUp"));
const SignOut = React.lazy(() => import("../pages/ExtraPages/SignOut"));
const UpdatePassword = React.lazy(() => import("../pages/ExtraPages/UpdatePassword"));
const ConfirmationCode = React.lazy(() => import("../pages/ExtraPages/ConfirmationCode"));
const ResetPassword = React.lazy(() => import("../pages/ExtraPages/ResetPassword"));
const Error404 = React.lazy(() => import("../pages/ExtraPages/Error404"));



const MyProfile = React.lazy(() => import("../pages/Profile/MyProfile/MyProfile"));


const Bookings = React.lazy(() => import("../pages/Bookings/Bookings"));

const Store = React.lazy(() => import("../pages/Store/Store"));
const ViewProduct = React.lazy(() => import("../pages/Store/ViewProduct"));

const Tours = React.lazy(() => import("../pages/Tours/Tours"));
const ViewTour = React.lazy(() => import("../pages/Tours/ViewTour"));

const Hajjs = React.lazy(() => import("../pages/Hajj/Hajjs"));
const ViewHajj = React.lazy(() => import("../pages/Hajj/ViewHajj"));

const Tickets = React.lazy(() => import("../pages/Tickets/Tickets"));
const ViewTicket = React.lazy(() => import("../pages/Tickets/ViewTicket"));

const Visas = React.lazy(() => import("../pages/Visas/Visas"));
const ViewVisa = React.lazy(() => import("../pages/Visas/RegisterVisa"));

const Hotels = React.lazy(() => import("../pages/Hotels/Hotels"));
const ViewHotel = React.lazy(() => import("../pages/Hotels/ViewHotel"));

const Transports = React.lazy(() => import("../pages/Tranasports/Transports"));
const ViewTransport = React.lazy(() => import("../pages/Tranasports/ViewTransport"));


const Layout2 = React.lazy(() => import("../pages/Home/Layout2/Layout2"));

const Agency = React.lazy(() => import("../pages/Agency/Agency"));
const Clients = React.lazy(() => import("../pages/Agency/Clients"));

const RegisterAgency = React.lazy(() => import("../pages/Agency/RegisterAgency"));

const ModuleSettings = React.lazy(() => import("../pages/ModuleSettings/ModuleSettings"));


const userRoutes = [
  { path: "/", component: Layout2 },
  { path: "/my-profile", component: MyProfile },
  { path: "/dashboard", component: Layout2 },

  { path: "/bookings", component: Bookings },
  
  { path: "/store", component: Store },
  { path: "/register-product", component: ViewProduct },

  { path: "/tours", component: Tours },
  { path: "/view-tour", component: ViewTour },

  { path: "/hajj", component: Hajjs },
  { path: "/view-hajj", component: ViewHajj },

  { path: "/tickets", component: Tickets },
  { path: "/view-ticket", component: ViewTicket },

  { path: "/visas", component: Visas },
  { path: "/view-visa", component: ViewVisa },
  
  { path: "/hotels", component: Hotels },
  { path: "/view-hotel", component: ViewHotel },
  
  { path: "/transports", component: Transports },
  { path: "/view-transport", component: ViewTransport },

  { path: "/clients", component: Clients },

  { path: "/agencies", component: Agency },
  { path: "/view-agency", component: RegisterAgency },

  { path: "/module-settings", component: ModuleSettings },



  // { path: "*", component: Error404 },
];

const authRoutes = [
  { path: "/reset-password", component: UpdatePassword },
  { path: "/signin", component: SignIn },
  { path: '/forget-password', component: ResetPassword },


  // { path: "/signout", component: SignOut },
  // { path: "/signup", component: SignUp },
  // { path: "/confirmation", component: ConfirmationCode },
  // { path: "*", component: Error404 },
];
export { userRoutes, authRoutes };
