import { createSlice } from "@reduxjs/toolkit";
import { AdminGetDashboardCounts } from "../apis/admin";

const initialState = {
  uname: "",
  name: "",
  email: "",
  phone: "",
  profile: "",
  isLogged: false,
  uid: 0,
  loggedate: ""
};

export const userSlice = createSlice({
  name: "CMSAdminUser",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setUName: (state, action) => {
      state.uname = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setIsLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setloggedate: (state, action) => {
      state.loggedate = action.payload;
    },
    setUid: (state, action) => {
      state.uid = action.payload;
    },

  },
  extraReducers: {
    // Extra reducer comes here
  },
});

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    svalue: "",
    country: "",
  },
  reducers: {
    setValue: (state, action) => {
      state.svalue = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
  },
});

// export const userEducation = createSlice({
//   name:"userEducation"
// })

export const { setName, setUName, setEmail, setPhone, setProfile, setIsLogged, setloggedate, setUid } = userSlice.actions;
export const { setValue, setCountry } = searchSlice.actions;




export const sideCountsSlice = createSlice({
  name: "CMSAdminSideCounts",
  initialState: {
    tours: 0,
    tickets: 0,
    hajjs: 0,
    transports: 0,
    visas: 0,
    rooms: 0,
    bookings: 0,
    agencies: 0,
    products: 0,
    clients: 0,
  },
  reducers: {
    setTours: (state, action) => {
      state.tours = action.payload;
    },
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setHajjs: (state, action) => {
      state.hajjs = action.payload;
    },
    setTransports: (state, action) => {
      state.transports = action.payload;
    },
    setVisas: (state, action) => {
      state.visas = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    setAgencies: (state, action) => {
      state.agencies = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
  },
  extraReducers: {
    // Extra reducer comes here
  },
});

export const { setTours, setTransports, setVisas, setRooms, setBookings, setAgencies, setProducts, setTickets, setHajjs, setClients } = sideCountsSlice.actions;

export const updateSidebarCounts = () => async (dispatch) => {
  AdminGetDashboardCounts().then(response => {
    if (response.status === 200) {
      dispatch(setTours(response.data.data.tours))
      dispatch(setTickets(response.data.data.tickets))
      dispatch(setHajjs(response.data.data.hajjs))
      dispatch(setVisas(response.data.data.visas))
      dispatch(setRooms(response.data.data.rooms))
      dispatch(setTransports(response.data.data.transports))
      dispatch(setBookings(response.data.data.bookings))
      dispatch(setAgencies(response.data.data.agencies))
      dispatch(setProducts(response.data.data.products))
      dispatch(setClients(response.data.data.clients))
    }
  }).catch(err => {
    console.log('eeeeeeeee', err.message);
    // setLoadingData(false);
  });
};


